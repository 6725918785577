import logo from "./logo.svg";
import React, { useState, useEffect, useRef } from "react";
import "./App.css";

import axios from "axios";

import awsConfig from "./aws-config";

const exchangeAuthorizationCodeForTokens_fake = async (code) => {
  console.log("[ App ] [ exchangeAuthorizationCodeForTokens FAKE ]: " + code);

  // Store tokens (localStorage, sessionStorage, etc.)
  localStorage.setItem("id_token", "ABCDE");
  localStorage.setItem("access_token", "FGHIJ");
  localStorage.setItem("refresh_token", "KLMNO");
  console.log(
    "[ App ] [ exchangeAuthorizationCodeForTokens ] Success:",
    "ABCDE",
    "FGHIJ",
    "KLMNO",
  );
};

const exchangeAuthorizationCodeForTokens = async (code) => {
  console.log("[ App ] [ exchangeAuthorizationCodeForTokens ]: " + code);
  const tokenUrl = `https://${awsConfig.domain}/oauth2/token`;

  const data = {
    grant_type: "authorization_code",
    client_id: awsConfig.userPoolWebClientId,
    redirect_uri: awsConfig.redirectSignIn,
    code: code,
  };

  console.log(
    "[ App ] [ exchangeAuthorizationCodeForTokens ] post: " +
      JSON.stringify(data),
  );
  const headers = {
    "Content-Type": "application/x-www-form-urlencoded",
  };

  const params = new URLSearchParams(data).toString();

  try {
    const response = await axios.post(tokenUrl, params, { headers });
    console.log(
      "[ App ] [ exchangeAuthorizationCodeForTokens ] TokenUrl " + tokenUrl,
    );
    console.log(
      "[ App ] [ exchangeAuthorizationCodeForTokens ] Success",
      response.data,
    );
    const { id_token, access_token, refresh_token } = response.data;

    // Store tokens (localStorage, sessionStorage, etc.)
    localStorage.setItem("id_token", id_token);
    localStorage.setItem("access_token", access_token);
    localStorage.setItem("refresh_token", refresh_token);
    console.log(
      "[ App ] [ exchangeAuthorizationCodeForTokens ] Success:",
      id_token,
      access_token,
      refresh_token,
    );
    //fetchPoints(id_token);
    window.location.href = window.location.href.split("?")[0];
  } catch (error) {
    console.error("Error exchanging code for tokens", error);
  }
};

function reset_page() {
  console.log("Debug: Reset Page");
  localStorage.removeItem("token");
  localStorage.removeItem("id_token");
  localStorage.removeItem("access_token");
  localStorage.removeItem("refresh_token");
}

function signin_callback_page(authorizationCode) {
  console.log("Debug: Login Callback Page");

  exchangeAuthorizationCodeForTokens(authorizationCode);

  return <a href="/">Go to main page</a>;
  //window.location.href = window.location.href.split("?")[0];
}

function App() {
  const [state, setState] = useState(0); // We don't need to use the state directly
  const urlParams = new URLSearchParams(window.location.search);
  const authorizationCode = urlParams.get("code");
  const effectRan = useRef(false);

  useEffect(() => {
    console.log("Code Change");
    if (effectRan.current === false && authorizationCode) {
      signin_callback_page(authorizationCode);
    }
    return () => {
      effectRan.current = true; // this will be set to true on the initial unmount
      console.log("Code Change Destructor ");
    };
  }, [authorizationCode]);

  const handleClick = () => {
    console.log("in HANDLECLICK");
    localStorage.removeItem("token");
    setState((prevState) => prevState + 1);
  };

  var jwt = localStorage.getItem("id_token");

  if (window.location.pathname == "/reset") {
    return reset_page();
  }

  return;
}

export default App;
