import React from "react";
import awsConfig from "./aws-config";

function login() {
  const { domain, redirectSignIn, responseType } = awsConfig;
  const oauthUrl = `https://${domain}/oauth2/authorize?redirect_uri=${encodeURIComponent(redirectSignIn)}&response_type=${responseType}&client_id=${awsConfig.userPoolWebClientId}&scope=email openid`;
  window.location.href = oauthUrl;
}

function Login() {
  var jwt = localStorage.getItem("id_token");
  if (jwt) {
    return;
  }
  return (
    <div>
      <h1> Please Login </h1>
      <p>Sorry I don't know who you are. Please Click login to solve this.</p>
      <button onClick={() => login()}> Login!</button>
    </div>
  );
}

export default Login;
