import React, { useState, useEffect } from "react";
export default Points;

function Points() {
  var id_token = localStorage.getItem("id_token");
  //var access_token = localStorage.getItem("access_token");
  //var refresh_token = localStorage.getItem("refresh_token");
  console.log("Debug: In Points Code.");
  console.log("Debug: Id: " + id_token);

  useEffect(() => {
    setTimeout(() => {
      fetchPoints(id_token);
    }, 1000);
  }, [id_token]);

  function fetchPoints(token) {
    if (token === "undefined" || token === null) {
      console.log(
        "[App] [fetchPoints]  Passed Token Not Set, so not accessing API",
      );
      return <div>Loading...</div>;
    }
    console.log("[App] [fetchPoints] Passed Token: " + token);
    var options = {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    };

    const url =
      process.env.NODE_ENV === "development"
        ? "https://k000hdol68.execute-api.eu-west-1.amazonaws.com/staging/my-points"
        : "https://k000hdol68.execute-api.eu-west-1.amazonaws.com/prod/my-points";

    fetch(url, options)
      .then((response) => response.json())
      .then((json) => setPoints({ json }))
      .then((json) => console.log(json))
      .catch((error) => {
        console.warn(error);
      });
  }

  const [my_points, setPoints] = useState(null);

  if (!my_points) return "";
  if (my_points.json.hasOwnProperty("error")) {
    console.log("[Error] " + my_points.json.error);
    return (
      <div>
        <h3 textcolor="Red">{my_points.json.error}</h3>
        <pre>{JSON.stringify(my_points, null, 2)}</pre>
      </div>
    );
  }
  console.log(my_points.json);
  // ToDo: why is the .json needed here?
  //
  return (
    <div>
      <div class="text-center">
        <h1 class="text-capitalize display-1"> {my_points.json.user} </h1>
        <h3> you have </h3>

        <div class="numberCircle mx-auto"> {my_points.json.points} </div>

        <div> well done for geting daddy points</div>
      </div>
      <hr />
      <div>
        <h3> Points History </h3>
        <table class="">
          <thead>
            <tr>
              <td>Points</td>
              <td>When</td>
              <td>Why</td>
            </tr>
          </thead>
          <tbody>
            {my_points.json.history.map((item, index) => (
              <tr>
                <td key={index}>{item.points}</td>
                <td>{new Date(item.timestamp * 1000).toLocaleString()}</td>
                <td>{item.reason || "No reason provided"}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <pre>
          {
            //JSON.stringify(my_points.json, null, 2)
          }
        </pre>
      </div>
    </div>
  );
}
