const awsConfig = {
  region: "eu-west-1",
  userPoolId: "eu-west-1_RmfhaAjqS",
  userPoolWebClientId: "1nf0m5a4i76kueo9lu8f02su3u",
  domain: "8c0544df5870.auth.eu-west-1.amazoncognito.com",
  redirectSignIn: "https://production.d1enx2579tq8sb.amplifyapp.com",
  redirectSignOut: "http://localhost:3000",
  responseType: "code", // For authorization code grant flow
};

export default awsConfig;
